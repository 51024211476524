import React from 'react';
import classNames from 'classnames';

import { EventSizeTypes } from '@features/Event/enum/EventSizeTypes';

interface IProps {
  stadium: string;
  eventSize?: EventSizeTypes;
  tournament?: string;
  isLight?: boolean;
}

export const AdditionalBlock: React.FC<IProps> = ({
  stadium,
  eventSize = EventSizeTypes.LARGE,
  tournament,
  isLight,
}) => {
  const isOneLine = eventSize === EventSizeTypes.MIDDLE || eventSize === EventSizeTypes.BIG;

  return (
    <div
      className={classNames('additional-block', {
        'additional-block--one-line': isOneLine,
        'additional-block--light': isLight,
        'additional-block--desktop': eventSize === EventSizeTypes.BIG || eventSize === EventSizeTypes.LARGE,
        'additional-block--mobile': eventSize === EventSizeTypes.MIDDLE || eventSize === EventSizeTypes.TINY,
      })}
    >
      {tournament && <div className='additional-block__item additional-block__item--first'>{tournament}</div>}
      <div
        className={classNames('additional-block__item', {
          'additional-block__item--height-big': !isOneLine && !tournament,
          'additional-block__item--with-dot': isOneLine && tournament,
        })}
      >
        {stadium}
      </div>
    </div>
  );
};
