import { AxiosPromise, AxiosResponse } from 'axios';

import { IData, ApiResponse } from '@common/api/ApiResponse';
import { isApiResponseType } from '@common/api/isApiResponseType';
import { ApiError } from '@common/api/ApiError';
import { RedirectError } from '@common/api/RedirectError';

const transformResponse = <T>(response: AxiosResponse<IData<T>>): ApiResponse<T> | never => {
  const { data } = response;

  if (isApiResponseType(data)) {
    return new ApiResponse(data);
  }

  throw Error(`Server returned invalid data: ${JSON.stringify(response)}`);
};

const checkRedirect = <Data>(response: ApiResponse<Data>): ApiResponse<Data> | never => {
  if (!response.location) {
    return response;
  }

  window.location.replace(response.location);

  throw new RedirectError();
};

export const createResponseFromAxios = <T>(promise: AxiosPromise<IData<T>>): Promise<ApiResponse<T>> =>
  promise
    .then(transformResponse)
    .then(checkRedirect)
    .catch(response => {
      if (response.isAxiosError && response.response) {
        throw new ApiError<ApiResponse<[]>>(transformResponse(response.response));
      }

      throw response;
    });
