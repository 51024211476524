import React from 'react';
import { Provider } from 'react-redux';
import { Registry, useComponentRegistry } from '@bem-react/di';
import { ConnectedRouter } from 'connected-react-router';
import moment from 'moment';

import { history, store } from '@apps/main/initStore';
import '@common/utils/i18n';
import { getLang } from '@common/utils/getLang';
import { AppTokens } from '@common/enums/AppTokens';
import { IComponentRegistry } from '@common/interfaces/mainComponentRegistry';
import { ThemeProviderWrapper } from '@common/components/ThemeProvider/ThemeProvider';

export const registry = new Registry({ id: AppTokens.MAIN_APP_TOKEN });

moment.locale(getLang());

export const App: React.FC = () => {
  const { MainPage } = useComponentRegistry<IComponentRegistry>(AppTokens.MAIN_APP_TOKEN);

  return (
    <Provider store={store}>
      <ThemeProviderWrapper>
        <ConnectedRouter history={history}>
          <MainPage />
        </ConnectedRouter>
      </ThemeProviderWrapper>
    </Provider>
  );
};
