import { IDateRange } from '@features/EventSelector/components/DateRangePicker/interfaces/dateRange';
import { DateTypes } from '@features/EventSelector/enum/DateTypes';

interface IParams {
  day: Date;
  range: IDateRange;
  setRange: (range: IDateRange) => void;
  currentDate: DateTypes;
}

export const setOutsideDateRange = ({ day, range, setRange, currentDate }: IParams) => {
  switch (currentDate) {
    case DateTypes.FROM: {
      setRange({
        from: range.from ? range.from : range.to,
        to: day,
      });

      break;
    }
    case DateTypes.TO: {
      setRange({
        from: day,
        to: range.to ? range.to : range.from,
      });
    }
  }
};
