import { ISlide as ISlideResponse } from '@common/interfaces/pageBlocks/slide';
import { ISlide } from '@features/SliderConnector/interfaces/ISlide';
import { SlideTypes as SlideTypesResponse } from '@common/enums/SlideTypes';
import { SlideTypes } from '@features/SliderConnector/enums/SlideTypes';
import { getAbonementShortCardFromResponse } from '@features/AbonementCard/utils/getAbonementShortCardFromResponse';
import { getEventInfoFromResponse } from '@features/Event/utils/getEventInfoFromResponse';
import { DynamicPageBlocks } from '@features/DynamicPage/enums/DynamicPageBlocks';
import { DynamicPageRoutes } from '@common/enums/DynamicPageRoutes';
import { getEventAnalyticsInfo } from '@features/DynamicPage/utils/getEventAnalyticsInfo';

export const getSlideFromResponse = (
  slide: ISlideResponse,
  blockType?: DynamicPageBlocks,
  pageRoute?: DynamicPageRoutes,
): ISlide => {
  if (slide.type === SlideTypesResponse.ABONEMENT) {
    return {
      type: SlideTypes.ABONEMENT,
      navigateTitle: slide.title,
      backgroundImage: slide.backgroundImage,
      backgroundVideo: slide.backgroundVideo,
      slide: {
        title: slide.description,
        card: getAbonementShortCardFromResponse(slide.abonementType),
      },
    };
  }

  if (slide.type === SlideTypesResponse.EVENT) {
    const eventAnalyticsInfo = getEventAnalyticsInfo({ pageRoute, event: slide.calendar });

    return {
      type: SlideTypes.EVENT,
      navigateTitle: slide.title,
      backgroundImage: slide.backgroundImage,
      backgroundVideo: slide.backgroundVideo,
      slide: {
        title: slide.description,
        event: getEventInfoFromResponse(slide.calendar, eventAnalyticsInfo?.product),
      },
    };
  }

  return {
    type: SlideTypes.TEXT,
    navigateTitle: slide.title,
    backgroundImage: slide.backgroundImage,
    backgroundVideo: slide.backgroundVideo,
    slide: {
      title: slide.description,
      button: slide.buttons[0] || undefined,
    },
  };
};
