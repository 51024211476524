import React from 'react';
import { useComponentRegistry } from '@bem-react/di';

import { IPageBlock } from '@features/DynamicPage/interfaces/IPageBlock';
import { DynamicPageBlocks } from '@features/DynamicPage/enums/DynamicPageBlocks';
import { IComponentRegistry } from '@common/interfaces/mainComponentRegistry';
import { AppTokens } from '@common/enums/AppTokens';
import { DEFAULT_SLIDE_TIME } from '@common/utils/constants';

export interface IProps {
  blocks: IPageBlock[];
}

export const ContainerList: React.FC<IProps> = ({ blocks }) => {
  const { SliderConnector, CardConnector, EventListCard, CardConnectorDouble, EventSelectorConnector } =
    useComponentRegistry<IComponentRegistry>(AppTokens.MAIN_APP_TOKEN);

  return (
    <div className='main-page__container-list'>
      {blocks.map((block, idx) => {
        switch (block.type) {
          case DynamicPageBlocks.SLIDER:
            return (
              <div
                className='main-page__slider main-page__container-list-item'
                key={idx}
              >
                <SliderConnector
                  isNotBorderedBottom={true}
                  timeout={DEFAULT_SLIDE_TIME}
                  slides={block.data}
                />
              </div>
            );
          case DynamicPageBlocks.EVENT_LIST_CARD:
            return (
              <div
                className='main-page__event-list-card main-page__container-list-item'
                key={idx}
              >
                <EventListCard {...block.data} />
              </div>
            );
          case DynamicPageBlocks.CARD:
            return (
              <div
                className='main-page__card main-page__container-list-item'
                key={idx}
              >
                <CardConnector {...block.data} />
              </div>
            );
          case DynamicPageBlocks.CARD_DOUBLE:
            return (
              <div
                className='main-page__card-double main-page__container-list-item'
                key={idx}
              >
                <CardConnectorDouble cards={block.data} />
              </div>
            );
          case DynamicPageBlocks.EVENT_SELECTOR:
            return (
              <div
                className='main-page__event-selector main-page__container-list-item'
                key={idx}
              >
                <EventSelectorConnector {...block.data} />
              </div>
            );
        }
      })}
    </div>
  );
};
