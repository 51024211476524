import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FetchStatus } from '@common/enums/FetchStatus';
import { ICheckPersonRestrictionParams } from '@common/interfaces/checkPersonRestrictionParams';
import { IButtonFetchStatus } from '@features/MainButton/interfaces/buttonFetchStatus';

export const asyncActions = {
  onCheckPersonRestrictions: createAction<ICheckPersonRestrictionParams>('mainButton/onCheckPersonRestrictions'),
};

export interface IState {
  popup: {
    isShown: boolean;
    isError: boolean;
    text?: string | null;
  };
  fetchStatus: IButtonFetchStatus | null;
}

export const { reducer, actions } = createSlice({
  name: 'mainButton',
  initialState: {
    popup: {
      isShown: false,
      isError: false,
      text: '',
    },
    fetchStatus: null,
  } as IState,
  reducers: {
    showPopup: (state, action: PayloadAction<string>) => {
      state.popup.isShown = true;
      state.popup.text = action.payload;
    },
    setFetchStatus: (
      state,
      action: PayloadAction<{
        fetchStatus: FetchStatus;
        id: number;
        error?: string | null;
      }>,
    ) => {
      const { fetchStatus, id, error } = action.payload;

      state.fetchStatus = { id, status: fetchStatus };

      if (fetchStatus === FetchStatus.FAILED) {
        state.popup.isShown = true;
        state.popup.text = error;
        state.popup.isError = true;
      }
    },
    resetPopup: state => {
      state.popup.isShown = false;
    },
  },
});
