// TODO Перенести глобальные переменные цвета в enum.

// Цвет текста в соответствии с дизайном проекта
export enum TextColor {
  NEUTRAL_300 = '#0D171E',
  NEUTRAL_200 = '#282828',
  NEUTRAL_100 = '#999',
  BRAND_DARK = '#009EE1',
  BRAND_HOVER = '#007EE1',
  ACCENT = '#FFFFFF',
}
