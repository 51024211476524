import React from 'react';
import classNames from 'classnames';

import { AdditionalBlock } from '@features/Event/components/AdditionalBlock';
import { MembersBlock } from '@features/Event/components/MembersBlock';
import { DateBlock } from '@features/Event/components/DateBlock';
import { IEventDefaultProps } from '@features/Event/interfaces/eventDefaultProps';
import { EventSizeTypes } from '@features/Event/enum/EventSizeTypes';
import { ButtonsBlock } from '@features/Event/components/ButtonsBlock';
import { EventCardSizeTypes } from '@features/Event/enum/EventCardSizeTypes';

export const EventCardTiny: React.FC<IEventDefaultProps> = ({ item, className, isLight }) => (
  <div
    className={classNames(
      'event-card',
      {
        'event-card--light': isLight,
      },
      `event-card--${EventCardSizeTypes.TINY}`,
      className,
    )}
  >
    {item.date && (
      <div className='event-card__top'>
        <DateBlock
          date={item.date}
          eventSize={EventSizeTypes.TINY}
          needLocalTimeNote={item.date ? item.date.needLocalTimeNote : false}
        />
      </div>
    )}

    <div className='event-card__content'>
      <MembersBlock
        name={item.name}
        logo={item.logo}
        teams={item.teams}
        eventSize={EventSizeTypes.LARGE}
      />
    </div>

    <div className='event-card__bottom'>
      <AdditionalBlock
        stadium={item.additionalInfo.stadium}
        tournament={item.teams && item.additionalInfo.tournament}
        eventSize={EventSizeTypes.MIDDLE}
        isLight={isLight}
      />
    </div>

    {item.buttons && (
      <div className='event-card__bottom'>
        <ButtonsBlock
          buttons={item.buttons}
          calendarId={item.id}
          eventSize={EventSizeTypes.TINY}
          isLight={isLight}
          caption={item.shortEventDescription}
          captionFullText={item.fullEventDescription}
          analytics={item.analyticsInfo}
        />
      </div>
    )}
  </div>
);
