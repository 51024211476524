import { EVENT_SELECTOR_RESULT_JS_SELECTOR } from '@features/EventSelector/utils/constants';
import { MAIN_PAGE_WRAPPER_SCROLL_CLASS } from '@features/DynamicPage/components/MainPage/utils/constants';

export const eventSelectorResultScrollHandler = (eventTarget: EventTarget | null) => {
  const scrollMainPage = document.getElementsByClassName(MAIN_PAGE_WRAPPER_SCROLL_CLASS)[0];
  const eventSelectorResult = scrollMainPage?.getElementsByClassName(EVENT_SELECTOR_RESULT_JS_SELECTOR)[0];

  if (!eventSelectorResult) {
    return {
      isWheelEventOnEventSelectorResult: false,
      isEventSelectorResultScrolledToBottom: false,
    };
  }

  const isEventSelectorResultScrolledToBottom =
    Math.ceil(eventSelectorResult.scrollTop) + eventSelectorResult.clientHeight === eventSelectorResult.scrollHeight;

  if (!eventTarget) {
    return {
      isWheelEventOnEventSelectorResult: false,
      isEventSelectorResultScrolledToBottom,
    };
  }

  return {
    isWheelEventOnEventSelectorResult: eventSelectorResult.contains(eventTarget as HTMLElement),
    isEventSelectorResultScrolledToBottom,
  };
};
