import React from 'react';
import classNames from 'classnames';

import { useDateFormat } from '@features/Event/hooks/useDateFormate';
import { EventSizeTypes } from '@features/Event/enum/EventSizeTypes';
import { IEventInfoDate } from '@features/Event/interfaces/eventInfo';
import { useEventsMainTranslation } from '@common/hooks/useEventsMainTranslation';
import { withSuspense } from '@common/hocs/withSuspense';

export interface IProps {
  date?: IEventInfoDate;
  dateNote?: string;
  elementIfDateNone?: React.ReactNode;
  eventSize: EventSizeTypes;
  needLocalTimeNote: boolean;
}

export const DateBlockComponent: React.FC<IProps> = ({ date, elementIfDateNone, eventSize, needLocalTimeNote }) => {
  const isOneLine = eventSize === EventSizeTypes.TINY;
  const t = useEventsMainTranslation();

  const dateFormatted = useDateFormat({
    date,
    isOneLine,
  });

  if (dateFormatted) {
    return (
      <div
        className={classNames('date-block', {
          'date-block--width-auto': isOneLine,
          [`date-block--${eventSize}`]: eventSize,
        })}
      >
        <div className='date-block__date'>
          {dateFormatted}
          <span className='date-block__date--local-note'>{needLocalTimeNote ? ` ${t('localTime')}` : ``}</span>
        </div>
      </div>
    );
  }

  if (elementIfDateNone) {
    return (
      <div
        className={classNames('date-block', {
          'date-block--width-auto': eventSize === EventSizeTypes.MIDDLE,
          [`date-block--${eventSize}`]: eventSize,
        })}
      >
        {elementIfDateNone}
      </div>
    );
  }

  return null;
};

export const DateBlock = withSuspense(DateBlockComponent, <div style={{ height: 51 }} />);
