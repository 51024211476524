export enum BaseColor {
  BRAND_DARK = '#009EE1',
  BRAND_HOVER = '#014493',
  BRAND_ACCENT500 = '#009EE14D',
  BRAND_ACCENT400 = '#0079C2',
  BRAND_ACCENT300 = '#e6f5fc',
  BRAND_ACCENT200 = '#E6F6FC',
  BRAND_ACCENT100 = '#F3F7FB',
  BRAND_ACCENT50 = '#FAFAFA',
  BRAND_CONTRAST = '#F2994A',
  CARD = '#f1f1f5',
  CARD_BORDER200 = '#F2F2F2',
  CARD_BORDER400 = '#C4C4C4',
  CARD_ACCENT = '#F3F7FB',
  STROKE = '#E3E3E3',
  TRANSPARENT = 'transparent',
  ACCENT = '#FFF',
  ACCENT_DARK = '#000',
}
