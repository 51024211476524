import React from 'react';

import { IEventSlide } from '@features/EventSlide/interfaces/IEventSlide';
import { EventCardBig } from '@features/Event/EventCard@big';
import { EventCardTiny } from '@features/Event/EventCard@tiny';

export interface IProps {
  isShort?: boolean;
}

export const EventSlideDesktop: React.FC<IEventSlide & IProps> = ({ event, title, isShort }) => (
  <div className='slider-item slider-item--event slider-item--height-100'>
    <div className='slider-item__title'>{title}</div>
    {isShort ? (
      <EventCardTiny
        isLight={true}
        item={event}
      />
    ) : (
      <EventCardBig
        isLight={true}
        item={event}
      />
    )}
  </div>
);
