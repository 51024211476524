import { ApiResponse } from '@common/api/ApiResponse';
import { ApiServiceBase } from '@common/api/ApiServiceBase';
import { ICalendar } from '@common/interfaces/commonResponse/calendar';
import { Locales } from '@common/enums/Locales';
import { IAvailableCalendarsParams } from '@common/interfaces/calendarRequest/availableCalendarsParams';
import { IPersonRestriction } from '@common/interfaces/calendarResponse/personRestriction';

export class CalendarApiService extends ApiServiceBase {
  constructor(private readonly locale: Locales = Locales.RU) {
    super();
  }

  private getUrl(url: string): string {
    return `${this.locale === Locales.RU ? '' : this.locale}/api/internal/v1/calendars${url}`;
  }

  public getAvailableCalendars(params?: IAvailableCalendarsParams): Promise<ApiResponse<ICalendar[]>> {
    return this.get<ICalendar[]>(this.getUrl('/find/available_events'), { params });
  }

  public checkPersonRestrictions(calendarBitrixId: number): Promise<ApiResponse<IPersonRestriction>> {
    return this.get<IPersonRestriction>(this.getUrl(`/${calendarBitrixId}/check_person_restrictions`));
  }
}
