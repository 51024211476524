import { all, call, getContext, put, takeEvery } from 'redux-saga/effects';

import { ServiceTokens } from '@common/enums/ServiceTokens';
import { ApiResponse } from '@common/api/ApiResponse';
import { CalendarApiService } from '@common/api/CalendarApiService';
import { ICalendar } from '@common/interfaces/commonResponse/calendar';
import { asyncActions, actions } from '@features/EventSelector/duck/slice';
import { actions as MainPageActions } from '@features/DynamicPage/components/MainPage/duck/slice';
import { FetchStatus } from '@common/enums/FetchStatus';

function* getAvailableCalendars(action: ReturnType<typeof asyncActions.onGetAvailableCalendars>) {
  try {
    yield put(actions.setFetchStatus(FetchStatus.IN_PROGRESS));

    const apiService: CalendarApiService = yield getContext(ServiceTokens.CALENDAR_API_SERVICE);

    const response: ApiResponse<ICalendar[]> = yield call(
      [apiService, apiService.getAvailableCalendars],
      action.payload,
    );

    yield all([put(actions.setAvailableCalendars(response.payload)), put(actions.setFetchStatus(FetchStatus.SUCCESS))]);
  } catch (e) {
    yield put(MainPageActions.setFetchStatus(FetchStatus.FAILED, e?.response.message));
    yield put(actions.setFetchStatus(FetchStatus.SUCCESS));
  }
}

export function* saga() {
  yield takeEvery(asyncActions.onGetAvailableCalendars, getAvailableCalendars);
}
