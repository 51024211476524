import { DateUtils } from 'react-day-picker';

import { IDateRange } from '@features/EventSelector/components/DateRangePicker/interfaces/dateRange';
import { isDateRangeFulfilled } from '@features/EventSelector/components/DateRangePicker/typeGuards/isDateRangeFulfilled';

interface IParams {
  day: Date;
  range: IDateRange;
}

export const isDayInRange = ({ day, range }: IParams) =>
  isDateRangeFulfilled(range) && DateUtils.isDayInRange(day, range);
