import moment from 'moment';

import {
  DATE_FORMAT,
  DATE_FORMAT_LINE_BREAK,
  DATE_FORMAT_WITH_YEAR,
  DATE_FORMAT_WITH_YEAR_LINE_BREAK,
  DATE_FORMAT_WITH_YEAR_WITHOUT_TIME,
  DATE_FORMAT_WITHOUT_TIME,
  DATE_FORMAT_WITH_YEAR_WITHOUT_DAY,
} from '@features/Event/utils/constants';
import { IEventInfoDate } from '@features/Event/interfaces/eventInfo';
import { getIsEventDateNextYear } from '@features/Event/utils/getIsEventDateNextYear';

interface IParams {
  date?: IEventInfoDate;
  isOneLine: boolean;
}

export const useDateFormat = ({ date, isOneLine }: IParams) => {
  if (!date || !date.value) {
    return;
  }

  const isEventDateNextYear = getIsEventDateNextYear(date);
  const isWithoutTime = !date.time;

  const dateFormatWithoutYearByTime = isWithoutTime ? DATE_FORMAT_WITHOUT_TIME : DATE_FORMAT;
  const dateFormatWithYearByTime = isWithoutTime ? DATE_FORMAT_WITH_YEAR_WITHOUT_TIME : DATE_FORMAT_WITH_YEAR;

  const dateFormatWithoutYearByLineBreak =
    isOneLine || isWithoutTime ? dateFormatWithoutYearByTime : DATE_FORMAT_LINE_BREAK;
  const dateFormatWithYearByLineBreak =
    isOneLine || isWithoutTime ? dateFormatWithYearByTime : DATE_FORMAT_WITH_YEAR_LINE_BREAK;

  const dateFormatByYear = isEventDateNextYear ? dateFormatWithYearByLineBreak : dateFormatWithoutYearByLineBreak;

  const dateFormat = date.isDayKnown ? dateFormatByYear : DATE_FORMAT_WITH_YEAR_WITHOUT_DAY;

  return moment.parseZone(date.value).format(dateFormat);
};
