import React, { Suspense } from 'react';
import classNames from 'classnames';

import { AdditionalBlock } from '@features/Event/components/AdditionalBlock';
import { MembersBlock } from '@features/Event/components/MembersBlock';
import { DateBlock } from '@features/Event/components/DateBlock';
import { IEventDefaultProps } from '@features/Event/interfaces/eventDefaultProps';
import { EventSizeTypes } from '@features/Event/enum/EventSizeTypes';
import { ButtonsBlock } from '@features/Event/components/ButtonsBlock';
import { Preloader } from '@common/components/Preloader/Preloader';
import { TextBlock } from '@features/Event/components/TextBlock';
import { BeforeMatchInfoBlock } from '@features/Event/components/BeforeMatchInfoBlock';

export const EventTiny: React.FC<IEventDefaultProps> = ({
  item,
  className,
  isLight,
  children,
  hideBeforeMatchInfo,
}) => (
  <Suspense fallback={<Preloader />}>
    <div
      className={classNames(
        'event-item',
        {
          'event-item--light': isLight,
        },
        `event-item--${EventSizeTypes.TINY}`,
        className,
      )}
    >
      {item.date && (
        <div className='event-item__top'>
          {(item.dateDescription && !item.date) ? (
            <TextBlock
              eventSize={EventSizeTypes.TINY}
              description={item.dateDescription}
            />
          ) : (
            <DateBlock
              date={item.date}
              eventSize={EventSizeTypes.TINY}
              needLocalTimeNote={item.date ? item.date.needLocalTimeNote : false}
            />
          )}

          {children}
        </div>
      )}

      <div className='event-item__content'>
        <MembersBlock
          name={item.name}
          logo={item.logo}
          teams={item.teams}
          eventSize={EventSizeTypes.TINY}
        />
      </div>

      {!hideBeforeMatchInfo && (
        <BeforeMatchInfoBlock
          description={item.beforeMatchInfoDescription}
          image={item.beforeMatchInfoImage}
        />
      )}

      <div className='event-item__bottom'>
        <AdditionalBlock
          stadium={item.additionalInfo.stadium}
          tournament={item.teams && item.additionalInfo.tournament}
          eventSize={EventSizeTypes.TINY}
          isLight={isLight}
        />
      </div>

      {item.buttons && (
        <div className='event-item__bottom'>
          <ButtonsBlock
            buttons={item.buttons}
            calendarId={item.id}
            eventSize={EventSizeTypes.TINY}
            isLight={isLight}
            caption={item.shortEventDescription}
            captionFullText={item.fullEventDescription}
            analytics={item.analyticsInfo}
          />
        </div>
      )}
    </div>
  </Suspense>
);
