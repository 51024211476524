import { useEffect } from 'react';
import debounce from 'lodash/debounce';

export const useWindowResizeEffect = <T>(
  onResize: () => void,
  deps: T[] = [],
  debounceTime = 100,
  immediate = false,
) => {
  useEffect(() => {
    const resizeHandler = debounce(onResize, debounceTime);

    if (immediate) {
      resizeHandler();
    }

    window.addEventListener('resize', resizeHandler);

    return () => window.removeEventListener('resize', resizeHandler);
  }, [onResize, debounceTime, ...deps]);
};
