import { IVipBusinessClubAboutClosestEvents } from '@features/VipInfo/interfaces/IVipBusinessClubAboutClosestEvents';

import { IVipBusinessClubClosestEventsPreview } from '@common/interfaces/pageBlocks/IVipBusinessClubClosestEvents';

export const getVipBusinessClubClosestEvents = (
  response: IVipBusinessClubClosestEventsPreview,
): IVipBusinessClubAboutClosestEvents => ({
  abonement: response.abonement,
  events: response.events,
  page: 'business_club',
});
