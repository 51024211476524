import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@apps/main/initStore';
import { getMainPageBlocksFromResponse } from '@features/DynamicPage/components/MainPage/utils/getMainPageBlocksFromResponse';
import { getMainPageAnalyticsFromResponse } from '@features/DynamicPage/components/MainPage/utils/getMainPageAnalyticsFromResponse';

export const mainPageResponseSelector = (state: IRootState) => state.mainPage.response;
export const fetchStatusSelector = (state: IRootState) => state.mainPage.fetchStatus;

export const mainPageSelector = createSelector(mainPageResponseSelector, getMainPageBlocksFromResponse);

export const mainPageAnalyticsSelector = createSelector(mainPageResponseSelector, getMainPageAnalyticsFromResponse);
