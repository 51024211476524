import { Fade, Modal } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

import '@img/icons/cross.svg';
import { SvgUse } from '@common/components/SvgUse/SvgUse';
import { ScreenResolution } from '@common/components/ThemeProvider/enums/ScreenResolution';

interface IProps {
  /**
   * Состояние окна
   */
  isOpen: boolean;
  /**
   * Функция при закрытии окна
   */
  onClose: () => void;
  /**
   * Доступ к стилям контейнера
   */
  className?: string;
}

/**
 * Типовой компонент модального окна с кнопкой закрытия.
 * @param className - доступ к стилям контейнера;
 * @param isOpen - состояние окна, boolean;
 * @param onClose - функция при закрытии окна;
 * @param children - содержимое окна;
 */
// TODO вынести в общие компоненты!!!
export const ModalView: React.FC<IProps> = ({ className, isOpen, onClose, children }) => (
  <SModal
    open={isOpen}
    onClose={onClose}
    closeAfterTransition={true}
    className={className}
  >
    <Fade
      in={isOpen}
      timeout={200}
    >
      <SModalWindow>
        <SCloseButton onClick={onClose}>
          <SvgUse use='cross' />
        </SCloseButton>
        {children}
      </SModalWindow>
    </Fade>
  </SModal>
);

const SModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus-within {
    outline: none;
    border: none;
  }
`;

const SModalWindow = styled.div`
  position: absolute;
  // Динамическая ширина - минимальная ширина модального окна 880px,
  // максимальная 1000px, изменяется в диапазоне разрешений от 1920 до 900
  // todo Убрать оверинжиниринговую функцию, заменить адаптивной логикой попроще
  max-width: Calc(880px + (1000 - 880) * ((100vw - 900px) / (1920 - 900)));
  background-color: ${({ theme }) => theme.matchProgram.colors.base.ACCENT};
  border-radius: 20px;
  height: min-content;
  max-height: Calc(100% - 100px);
  overflow: auto;

  &:focus-visible {
    outline: none;
  }

  @media (max-width: ${ScreenResolution.TABLET}) {
    width: 100%;
    height: min-content;
    max-width: unset;
    max-height: calc(100% - ${window.innerHeight * 0.07}px);
    bottom: 0;
    border-radius: 20px 20px 0 0;
    padding-bottom: 10px;
  }
`;

const SCloseButton = styled.div`
  z-index: 2;
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.matchProgram.colors.base.ACCENT};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  top: 10px;
  right: 10px;

  svg {
    width: 14px;
    height: 14px;
  }
`;
