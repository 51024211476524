import React from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import { Popup } from '@common/components/Popup/Popup';
import {
  IWithErrorPopupState,
  withErrorPopupState,
} from '@features/DynamicPage/components/MainPage/components/MainPageErrorPopup/hocs/withStadiumErrorPopupState';
import { FetchStatus } from '@common/enums/FetchStatus';

export const MainPageErrorPopupComponent: React.FC<IWithErrorPopupState> = ({ fetchStatus, error, resetPopup }) => {
  const { t: tt } = useTranslation();

  return (
    <Popup
      title={tt('error')}
      isShown={fetchStatus === FetchStatus.FAILED}
      onClose={() => resetPopup(error)}
    >
      <div>{error ? parse(error) : tt('unknown_error')}</div>
    </Popup>
  );
};

export const MainPageErrorPopup = withErrorPopupState(MainPageErrorPopupComponent);
