import React from 'react';
import classNames from 'classnames';

import { AdditionalBlock } from '@features/Event/components/AdditionalBlock';
import { MembersBlock } from '@features/Event/components/MembersBlock';
import { DateBlock } from '@features/Event/components/DateBlock';
import { IEventDefaultProps } from '@features/Event/interfaces/eventDefaultProps';
import { EventSizeTypes } from '@features/Event/enum/EventSizeTypes';
import { ButtonsBlock } from '@features/Event/components/ButtonsBlock';
import { ButtonsCaption } from '@features/Event/components/ButtonsCaption';
import { TextBlock } from '@features/Event/components/TextBlock';
import { BeforeMatchInfoBlock } from '@features/Event/components/BeforeMatchInfoBlock';

export const EventMiddle: React.FC<IEventDefaultProps> = ({ item, className, isLight, hideBeforeMatchInfo }) => (
  <div
    className={classNames(
      'event-item',
      {
        'event-item--light': isLight,
      },
      `event-item--${EventSizeTypes.MIDDLE}`,
      className,
    )}
  >
    <div className='event-item__top'>
      <AdditionalBlock
        stadium={item.additionalInfo.stadium}
        tournament={item.teams && item.additionalInfo.tournament}
        eventSize={EventSizeTypes.MIDDLE}
        isLight={isLight}
      />
    </div>

    <div className='event-item__content'>
      <div className='event-item__left-block'>
        <MembersBlock
          name={item.name}
          logo={item.logo}
          teams={item.teams}
          eventSize={EventSizeTypes.MIDDLE}
        />

        {!hideBeforeMatchInfo && (
          <BeforeMatchInfoBlock
            description={item.beforeMatchInfoDescription}
            image={item.beforeMatchInfoImage}
          />
        )}
      </div>

      {(item.dateDescription && !item.date) ? (
        <TextBlock
          eventSize={EventSizeTypes.MIDDLE}
          description={item.dateDescription}
        />
      ) : (
        <DateBlock
          date={item.date}
          eventSize={EventSizeTypes.MIDDLE}
          elementIfDateNone={
            item.buttons && (
              <ButtonsBlock
                buttons={item.buttons}
                calendarId={item.id}
                eventSize={EventSizeTypes.MIDDLE}
                isLight={isLight}
                analytics={item.analyticsInfo}
              />
            )
          }
          needLocalTimeNote={item.date ? item.date.needLocalTimeNote : false}
        />
      )}
    </div>

    {item.date && item.buttons && (
      <div className='event-item__bottom'>
        <ButtonsBlock
          buttons={item.buttons}
          calendarId={item.id}
          eventSize={EventSizeTypes.MIDDLE}
          isLight={isLight}
          caption={item.shortEventDescription}
          captionFullText={item.fullEventDescription}
          analytics={item.analyticsInfo}
        />
      </div>
    )}

    {!item.date && item.shortEventDescription && (
      <div className='event-item__bottom'>
        <ButtonsCaption
          isLight={isLight}
          eventSize={EventSizeTypes.MIDDLE}
          caption={item.shortEventDescription}
          captionFullText={item.fullEventDescription}
          isWidthFull={true}
          calendarId={item.id}
        />
      </div>
    )}
  </div>
);
