import React from 'react';
import classNames from 'classnames';

import { SvgUse } from '@common/components/SvgUse/SvgUse';

export interface IProps {
  title: string;
  eventsCount?: number;
  onResetClick?: () => void;
  isEventsFetched?: boolean;
  isMobile?: boolean;
}

export const Header: React.FC<IProps> = ({ title, eventsCount, onResetClick, isEventsFetched, isMobile }) => (
  <div className='event-selector__header'>
    <div
      className={classNames('event-selector__title', {
        'event-selector__title--little': isEventsFetched,
      })}
    >
      {title}
    </div>

    {isEventsFetched && (
<div className='event-selector__title-caption'>
{eventsCount || 0}
{' '}
результатов
</div>
    )}

    {isMobile && isEventsFetched && (
      <SvgUse
        use='cross'
        className='event-selector__close-icon'
        onClick={onResetClick}
      />
    )}
  </div>
);
