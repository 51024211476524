import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

import { TextColor } from '@common/components/ThemeProvider/enums/TextColor';
import { ScreenResolution } from '@common/components/ThemeProvider/enums/ScreenResolution';

export const GlobalStyle = createGlobalStyle`
  ${reset}
  
  @font-face {
    font-family: "PT Sans";
    src: url('/assets/_/fonts/PTSans-Regular.ttf');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "PT Sans";
    src: url('/assets/_/fonts/PTSans-Bold.ttf');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: "FuturaDemi";
    src: url('/assets/_/fonts/FuturaDemiC.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'FuturaPT-Book';
    src: url('/assets/_/fonts/FuturaPT-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'FuturaPT-Heavy';
    src: url('/assets/_/fonts/FuturaPT-Heavy.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }


  @font-face {
    font-family: 'FuturaPT-Bold';
    src: url('/assets/_/fonts/FuturaPT-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'FuturaPT-Demi';
    src: url('/assets/_/fonts/FuturaPT-Demi.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'FuturaPT-Medium';
    src: url('/assets/_/fonts/FuturaPT-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  html {
    scroll-behavior: smooth;
  }
  
  *:active {
    -webkit-tap-highlight-color: rgba(0,0,0,0); /* Отключение моментального свечения на iOS */
    -webkit-tap-highlight-color: transparent; /* Отключение моментального свечения на Android */
    outline: none;
  }
  
  
  body {
    color: ${TextColor.NEUTRAL_300};
    text-decoration: none;
  }
  
  button {
    border: none;
  }

  // Убирает normalize для страницы
  // чтобы в админке можно было
  // вставлять такие теги и
  // и они были стилизованы
  // по-умолчанию.
  i {
    font-style: italic;
  }
  b {
    font-weight: bold;
  }
  
  /* Отступ от начала блока на странице */
  .page-block {
    padding-bottom: 40px;

    @media (max-width: ${ScreenResolution.TABLET}) {
      padding-bottom: 20px;
    }
  }
`;
