import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

import { TextColor } from '@common/components/ThemeProvider/enums/TextColor';
import { BaseColor } from '@common/components/ThemeProvider/enums/BaseColor';

export const SReactTooltip = styled(ReactTooltip)`
  &&& {
    ${({ theme }) => theme.eventsList.selectedCard.captions.tooltipText};
    z-index: 3;
    left: -10px;
    color: ${TextColor.NEUTRAL_300} !important;
    opacity: 1 !important;
    padding: 10px 15px;
    background-color: ${BaseColor.ACCENT};
    // TODO вынести в тему или применить другой цвет из темы
    box-shadow: 0px 4px 16px 0px #0000001a;
    max-width: 320px;

    // треугольник-стрелка у тултипа
    &:before,
    &:after {
      content: unset;
    }
  }
`;
