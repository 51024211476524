import React from 'react';
import classNames from 'classnames';

import cardBackground from '@img/stadium.png';
import { IWithTemplate } from '@common/hocs/withTemplate';
import { Template } from '@common/enums/Template';
import { useIsTablet } from '@common/hooks/useIsTablet';
import { IWithEventSelectorState, withEventSelectorState } from '@features/EventSelector/hocs/withEventSelectorState';
import { IEventSelectorProps } from '@features/EventSelector/interfaces/eventSelectorProps';
import { Card } from '@features/Card/Card';
import { EventSelector } from '@features/EventSelector/EventSelector';
import { BackgroundFileTypes } from '@common/enums/BackgroundFileTypes';

const EventSelectorConnectorComponent: React.FC<IWithEventSelectorState & IWithTemplate & IEventSelectorProps> = ({
  events,
  template,
  ...props
}) => {
  const isTablet = useIsTablet();
  const isEventsFetched = events !== null;

  if (template === Template.DESKTOP) {
    return (
      <div
        className={classNames('event-selector-wrapper', {
          'event-selector-wrapper--pt60': !events,
          'event-selector-wrapper--pt30': events,
        })}
      >
        <EventSelector {...props} />
      </div>
    );
  }

  if (isTablet && template === Template.MOBILE) {
    return (
      <>
        {isEventsFetched ? (
          <div className='event-selector-wrapper'>
            <EventSelector
              {...props}
              isTablet={true}
            />
          </div>
        ) : (
          <Card
            backgroundImage={{
              type: BackgroundFileTypes.IMAGE,
              path: cardBackground,
            }}
          >
            <EventSelector
              {...props}
              isLight={true}
              isTablet={true}
            />
          </Card>
        )}
      </>
    );
  }

  return (
    <>
      {isEventsFetched ? (
        <div className='event-selector-wrapper'>
          <EventSelector
            {...props}
            isMobile={true}
          />
        </div>
      ) : (
        <Card
          backgroundImage={{
            type: BackgroundFileTypes.IMAGE,
            path: cardBackground,
          }}
        >
          <EventSelector
            {...props}
            isLight={true}
            isMobile={true}
          />
        </Card>
      )}
    </>
  );
};

export const EventSelectorConnector = withEventSelectorState(EventSelectorConnectorComponent);
