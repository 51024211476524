import React from 'react';
import classNames from 'classnames';

import { AdditionalBlock } from '@features/Event/components/AdditionalBlock';
import { MembersBlock } from '@features/Event/components/MembersBlock';
import { DateBlock } from '@features/Event/components/DateBlock';
import { IEventDefaultProps } from '@features/Event/interfaces/eventDefaultProps';
import { EventSizeTypes } from '@features/Event/enum/EventSizeTypes';
import { ButtonsBlock } from '@features/Event/components/ButtonsBlock';
import { TextBlock } from '@features/Event/components/TextBlock';
import { BeforeMatchInfoBlock } from '@features/Event/components/BeforeMatchInfoBlock';

export const EventLarge: React.FC<IEventDefaultProps> = ({ item, className, isLight }) => (
  <div
    className={classNames(
      'event-item',
      {
        'event-item--light': isLight,
      },
      `event-item--${EventSizeTypes.LARGE}`,
      className,
    )}
  >
    <div className='event-item__content'>
      <div className='event-item__left-block'>
        <MembersBlock
          name={item.name}
          logo={item.logo}
          teams={item.teams}
          eventSize={EventSizeTypes.LARGE}
        />
        <BeforeMatchInfoBlock
          description={item.beforeMatchInfoDescription}
          image={item.beforeMatchInfoImage}
        />
      </div>

      <AdditionalBlock
        stadium={item.additionalInfo.stadium}
        tournament={item.teams && item.date && item.additionalInfo.tournament}
        eventSize={EventSizeTypes.LARGE}
        isLight={isLight}
      />

      {(item.dateDescription && !item.date) ? (
        <TextBlock
          eventSize={EventSizeTypes.LARGE}
          description={item.dateDescription}
        />
      ) : (
        <DateBlock
          date={item.date}
          eventSize={EventSizeTypes.LARGE}
          elementIfDateNone={
            item.teams && (
              <div className='additional-block__item additional-block__item--height-big'>
                {item.additionalInfo.tournament}
              </div>
            )
          }
          needLocalTimeNote={item.date ? item.date.needLocalTimeNote : false}
        />
      )}
    </div>

    {item.buttons && (
      <ButtonsBlock
        buttons={item.buttons}
        calendarId={item.id}
        eventSize={EventSizeTypes.LARGE}
        isLight={isLight}
        caption={item.shortEventDescription}
        analytics={item.analyticsInfo}
        captionFullText={item.fullEventDescription}
      />
    )}
  </div>
);
