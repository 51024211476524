import { ThemeProvider } from 'styled-components';
import React from 'react';

import { GlobalStyle } from '@common/components/ThemeProvider/components/GlobalStyle';
import { defaultTheme } from '@common/components/ThemeProvider/utils/defaultTheme';

export const ThemeProviderWrapper: React.FC = ({ children }) => (
  <ThemeProvider theme={defaultTheme}>
    <GlobalStyle />
    {children}
  </ThemeProvider>
);
