import React, { cloneElement, FC, ReactNode, useEffect, useState } from 'react';

import ReactTooltip from 'react-tooltip';

import styled from 'styled-components';

import { ScreenResolution } from '@common/components/ThemeProvider/enums/ScreenResolution';
import { ModalView } from '@features/MatchProgram/components/common/ModalView/ModalView';
import { SReactTooltip } from '@features/EventsV2/components/styledComponents/SReactTooltip';

interface IProps {
  /**
   * id тултипа - для react-tooltip
   * // TODO рассмотреть как необязательное поле и генерировать его здесь
   */
  tooltipId: string;
  /**
   * текст тултипа
   */
  tooltipText: string;
  /**
   * Контент в модальном вместо тултипа
   * // TODO рассмотреть опциональное поле - вместо него может использоваться просто текст из тултипа
   */
  modalContent?: ReactNode;
  /**
   * Компонент вокруг которого происходит эта обертка
   * на которой появляется тултип / модалка
   */
  children: ReactNode;
}

/**
 * Компонент обертка для элемента который на десктопе показывает тултип, а на мобилке по тапу открывает модалку
 * @param tooltipText
 * @param tooltipId
 * @param children
 * @param modalContent
 * @constructor
 */
// TODO ВЫПИЛИТЬ ЭТОТ REACT-TOOLTIP И НАПИСАТЬ СВОЙ!!!! он в 10 из 9 кейсов бажит, прозрачный текст, z-index, кликабельность - везде бажит на хроме
export const DeviceAwareTooltipModal: FC<IProps> = ({ tooltipText, tooltipId, children, modalContent }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= parseInt(ScreenResolution.MOBILE));
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleClick = () => {
    if (isMobile) {
      setIsModalOpen(true);
    }
  };

  const handleMouseEnter = (event: MouseEvent) => {
    const targetElement = event.target as HTMLElement;

    if (!isMobile && targetElement) {
      ReactTooltip.show(targetElement);
    }
  };

  const handleMouseLeave = () => {
    if (isMobile) {
      return;
    }
    ReactTooltip.hide();
  };

  return (
    <>
      {cloneElement(children as React.ReactElement, {
        onClick: handleClick,
        onMouseEnter: (event: MouseEvent) => handleMouseEnter(event),
        onMouseLeave: handleMouseLeave,
        'data-tip': tooltipText,
        'data-for': tooltipId,
      })}
      {!isMobile && (
        <SReactTooltip
          id={tooltipId}
          place='left'
          effect='solid'
          multiline={true}
          clickable={true}
          globalEventOff='touchstart'
        >
          {tooltipText}
        </SReactTooltip>
      )}

      {isMobile && isModalOpen && (
        <ModalView
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        >
          <SModalConentWrapper>{modalContent}</SModalConentWrapper>
        </ModalView>
      )}
    </>
  );
};

const SModalConentWrapper = styled.section`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  padding: 40px;
  overflow: auto;
  @media (max-width: ${ScreenResolution.TABLET}) {
    padding: 10px;
    row-gap: 20px;
    // отступ сверху от кнопки крестика в окне
    padding-top: 40px;
  }
`;
