import { IButtonAction } from '@common/interfaces/commonResponse/button';
import { ButtonTypes } from '@common/enums/ButtonTypes';
import { IButtonFetchStatus } from '@features/MainButton/interfaces/buttonFetchStatus';
import { FetchStatus } from '@common/enums/FetchStatus';

interface IParams {
  fetchStatus: IButtonFetchStatus | null;
  action: IButtonAction;
}

export const getIsFetching = ({ fetchStatus, action }: IParams) => {
  if (!fetchStatus || fetchStatus.status !== FetchStatus.IN_PROGRESS) {
    return false;
  }

  if (action.type === ButtonTypes.CALENDAR_ACTION) {
    return fetchStatus.id === action.bitrixEventId;
  }

  return false;
};
