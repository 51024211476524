import React, { Suspense, useEffect } from 'react';
import classNames from 'classnames';

import { ScrollBar } from '@features/ScrollBar/ScrollBar';
import {
  IWithMainPageState,
  withMainPageState,
} from '@features/DynamicPage/components/MainPage/hocs/withMainPageState';
import { useAnimatePage } from '@features/DynamicPage/components/MainPage/hooks/useAnimatePage';
import { AnimatedContainerList } from '@features/DynamicPage/components/MainPage/components/AnimatedContainerList';
import { Preloader } from '@common/components/Preloader/Preloader';
import { IWithTemplate } from '@common/hocs/withTemplate';
import { FetchStatus } from '@common/enums/FetchStatus';
import { MainPageErrorPopup } from '@features/DynamicPage/components/MainPage/components/MainPageErrorPopup/MainPageErrorPopup';
import { ContainerList } from '@features/DynamicPage/components/MainPage/components/ContainerList';
import { MainButtonPopup } from '@features/MainButton/components/MainButtonPopup/MainButtonPopup';
import { usePushAnalytics } from '@common/hooks/usePushAnalytics';
import { MAIN_PAGE_WRAPPER_SCROLL_CLASS } from '@features/DynamicPage/components/MainPage/utils/constants';

export const MainPageComponent: React.FC<IWithMainPageState & IWithTemplate> = ({
  blocks,
  analytics,
  template,
  fetchStatus,
  onInit,
}) => {
  const { activeItem, prevPage, nextPage, animatedInItem, animatedOutItem, setState, isRevert } = useAnimatePage(
    blocks.length,
    template,
  );

  usePushAnalytics(analytics);

  useEffect(() => {
    if (fetchStatus !== FetchStatus.NONE) {
      return;
    }

    onInit();
  }, []);

  if (fetchStatus !== FetchStatus.SUCCESS) {
    return <Preloader />;
  }

  return (
    <Suspense fallback={<Preloader />}>
      <div className='main-page'>
        <div className='main-page__wrapper main-page__wrapper--simple'>
          <ContainerList blocks={blocks} />
        </div>
        <div className={classNames('main-page__wrapper main-page__wrapper--scroll', MAIN_PAGE_WRAPPER_SCROLL_CLASS)}>
          <div className='main-page__scroll'>
            <ScrollBar
              onClickNext={nextPage}
              onClickPrev={prevPage}
              nextItem={blocks[activeItem + 1]?.title}
              prevItem={blocks[activeItem - 1]?.title}
            />
          </div>
          <div className='main-page__content'>
            <AnimatedContainerList
              blocks={blocks}
              activeItem={activeItem}
              animatedInItem={animatedInItem}
              animatedOutItem={animatedOutItem}
              setState={setState}
              isRevert={isRevert}
              template={template}
            />
          </div>
        </div>
      </div>

      <MainPageErrorPopup />

      <MainButtonPopup />
    </Suspense>
  );
};

export const MainPage = withMainPageState(MainPageComponent);
