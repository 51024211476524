import React from 'react';
import classNames from 'classnames';

import { IButtonsBlock } from '@features/Event/interfaces/eventInfo';
import { EventButtonKinds } from '@common/enums/EventButtonKinds';
import { ButtonsCaption } from '@features/Event/components/ButtonsCaption';
import { EventSizeTypes } from '@features/Event/enum/EventSizeTypes';
import { LEGACY_ADD_TO_BASKET_CLASS } from '@features/Event/utils/constants';

import { MainButton } from '@features/MainButton/MainButton';
import { getButtonSizeFromEventSize } from '@features/Event/utils/getButtonSizeFromEventSize';
import { onButtonClickWithAnalytics } from '@features/Event/utils/onButtonClickWithAnalytics';
import { IGoogleAnalyticsEvent } from '@modules/tracker/interfaces/IGoogleAnalyticsEvent';

export interface IProps {
  buttons: IButtonsBlock;
  calendarId?: number;
  caption?: string;
  captionFullText?: string;
  isLight?: boolean;
  eventSize: EventSizeTypes;
  withoutOffsetTop?: boolean;
  analytics?: IGoogleAnalyticsEvent;
}

export const ButtonsBlock: React.FC<IProps> = ({
  buttons,
  calendarId,
  caption,
  captionFullText,
  isLight,
  eventSize = EventSizeTypes.LARGE,
  withoutOffsetTop,
  analytics,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const isMobile = eventSize === EventSizeTypes.TINY || eventSize === EventSizeTypes.MIDDLE;
  const isMainButtonSubscribe = buttons.main.action.kind === EventButtonKinds.SUBSCRIBE;
  const isMainButtonSubscribed = buttons.main.action.kind === EventButtonKinds.SUBSCRIBED;
  const isMainButtonAddToBasket = buttons.main.action.kind === EventButtonKinds.ADD_TO_BASKET;
  const buttonsSize = getButtonSizeFromEventSize(eventSize);

  if (isMobile) {
    return (
      <div
        className={classNames('buttons-block', {
          [`buttons-block--${eventSize}`]: eventSize,
        })}
      >
        <div className='buttons-block__wrapper'>
          {buttons.additional && (
            <div className='buttons-block__additional-button'>
              <MainButton
                button={buttons.additional}
                isGold={buttons.additional.action.kind === EventButtonKinds.VIP}
                isOutline={buttons.additional.action.kind !== EventButtonKinds.VIP}
                size={buttonsSize}
                isLittleFont={true}
                notBlockLinkOnClick={() => onButtonClickWithAnalytics(analytics)}
              />
            </div>
          )}

          <MainButton
            button={buttons.main}
            isOutline={isMainButtonSubscribe}
            isLong={isMainButtonSubscribe}
            size={buttonsSize}
            className={isMainButtonAddToBasket ? LEGACY_ADD_TO_BASKET_CLASS : undefined}
            calendarId={isMainButtonAddToBasket ? calendarId : undefined}
            isLittleFont={true}
            notBlockLinkOnClick={() => onButtonClickWithAnalytics(analytics)}
            isLink={isMainButtonSubscribed}
          />
        </div>

        {caption && (
          <ButtonsCaption
            isLight={isLight}
            eventSize={eventSize}
            caption={caption}
            captionFullText={captionFullText}
            calendarId={calendarId ? calendarId : 0}
          />
        )}
      </div>
    );
  }

  return (
    <div
      className={classNames('buttons-block', {
        'buttons-block--offset-bottom': caption,
        'buttons-block--offset-top': !buttons.additional && !withoutOffsetTop,
        [`buttons-block--${eventSize}`]: eventSize,
      })}
    >
      {buttons.additional && (
        <div className='buttons-block__additional-button'>
          <MainButton
            button={buttons.additional}
            isGold={buttons.additional.action.kind === EventButtonKinds.VIP}
            isOutline={buttons.additional.action.kind !== EventButtonKinds.VIP}
            size={buttonsSize}
            isLittleFont={true}
            notBlockLinkOnClick={() => onButtonClickWithAnalytics(analytics)}
          />
        </div>
      )}

      <MainButton
        button={buttons.main}
        isOutline={isMainButtonSubscribe}
        isLong={isMainButtonSubscribe}
        size={buttonsSize}
        className={isMainButtonAddToBasket ? LEGACY_ADD_TO_BASKET_CLASS : undefined}
        calendarId={isMainButtonAddToBasket ? calendarId : undefined}
        isLittleFont={true}
        notBlockLinkOnClick={() => onButtonClickWithAnalytics(analytics)}
        isLink={isMainButtonSubscribed}
      />

      {caption && (
        <ButtonsCaption
          isLight={isLight}
          eventSize={eventSize}
          caption={caption}
          captionFullText={captionFullText}
          calendarId={calendarId ? calendarId : 0}
        />
      )}
    </div>
  );
};
