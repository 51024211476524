import React, { createElement, ReactNode } from 'react';
import classNames from 'classnames';

import { SvgUse } from '@common/components/SvgUse/SvgUse';
import { EventSizeTypes } from '@features/Event/enum/EventSizeTypes';
import { DeviceAwareTooltipModal } from '@features/EventsV2/components/EventsList/components/shared/DeviceAwareTooltipModal/DeviceAwareTooltipModal';

export interface IProps {
  eventSize: EventSizeTypes;
  caption: string;
  calendarId: number;
  captionFullText?: string;
  isLight?: boolean;
  isWidthFull?: boolean;
}

export const ButtonsCaption: React.FC<IProps> = ({
  eventSize = EventSizeTypes.LARGE,
  caption,
  isLight,
  isWidthFull,
  captionFullText,
  calendarId,
}) => {
  let modalContent: ReactNode|undefined = undefined;

  if (captionFullText) {
    modalContent = createElement(
        'div',
        { className: 'buttons-caption__description-modal-wrapper' },
        captionFullText
    );
  }

  return (
    <div
      className={classNames('buttons-caption', {
        'buttons-caption--light': isLight,
        'buttons-caption--full-width': isWidthFull,
        [`buttons-caption--${eventSize}`]: eventSize,
      })}
    >
      <DeviceAwareTooltipModal
        tooltipId={`button-cation-tooltip-${calendarId}`}
        tooltipText={captionFullText ? captionFullText : ''}
        modalContent={modalContent}
      >
        <div className='buttons-caption__description-container'>
          <SvgUse
            use='attention'
            className={classNames('buttons-caption__icon', {
              'buttons-caption__icon--light': isLight,
            })}
          />
          <span
            className='buttons-caption__text-container'
            dangerouslySetInnerHTML={{ __html: caption }}
          />
        </div>
      </DeviceAwareTooltipModal>
    </div>
  );
};
