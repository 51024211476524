import React, { AnchorHTMLAttributes } from 'react';
import classNames from 'classnames';
import parse from 'html-react-parser';

import { ButtonSizes } from '@features/MainButton/enum/ButtonSizes';
import { SvgUse } from '@common/components/SvgUse/SvgUse';
import '@img/icons/arrow-drop.svg';
import { ArrowPositions } from '@common/components/ButtonModern/enums/ArrowPositions';
import { AnimatedStar } from '@common/components/Preloader/AnimatedStar';

export interface IButtonModern {
  // TODO фактически это не чилдрен потому что он сужен до типа стринг которое потом парсится в jsx
  //  - рассмотреть рефакторинг этой пропсы на другое название. Временно установлен как опциональное поле
  children?: string;
  full?: boolean;
  calendarId?: number;
  size?: ButtonSizes;
  isOutline?: boolean;
  isGold?: boolean;
  isWhite?: boolean;
  isBlue?: boolean;
  isLong?: boolean;
  isBigHeight?: boolean;
  lightHover?: boolean;
  isDisabled?: boolean;
  isLittleFont?: boolean;
  arrowed?: ArrowPositions | boolean;
  isLink?: boolean;
  isPreloading?: boolean;
  isActive?: boolean;
  original?: boolean;
  // TODO разобраться что за  props.className, и где он используется - переименовать этот в случае необходимости в обычный className пропс
  styledClassName?: string;
}

export const ButtonModern: React.FC<IButtonModern & AnchorHTMLAttributes<HTMLAnchorElement>> = ({
  children,
  full,
  calendarId,
  size,
  isOutline,
  isGold,
  isWhite,
  isBlue,
  isLong,
  isBigHeight,
  lightHover,
  isDisabled,
  isLittleFont,
  arrowed,
  isLink,
  isPreloading,
  isActive,
  original,
  styledClassName,
  ...props
}) => (
  <a
    {...props}
    data-calendar={calendarId}
    title={isActive ? '' : 'Мероприятие неактивно'}
    className={classNames(
      'button-modern js-button',
      props.className,
      {
        'button-modern--w100': full,
        'button-modern--light-hover': lightHover,
        'button-modern--outline': isOutline,
        'button-modern--gold': isGold,
        'button-modern--white': isWhite,
        'button-modern--blue': isBlue,
        'button-modern--long': isLong,
        'button-modern--height-big': isBigHeight,
        'button-modern--fs-little': isLittleFont,
        'button-modern--disabled': isDisabled,
        'button-modern--preloaded': isPreloading,
        'button-modern--inactive': isActive === false,
        'button-modern--arrowed': arrowed,
        'button-modern--original': original,
        'button-modern--arrowed-right': arrowed === ArrowPositions.RIGHT,
        [`button-modern--${size}`]: size,
        'button-modern--link': isLink,
      },
      styledClassName,
    )}
  >
    {(arrowed === ArrowPositions.LEFT || arrowed === true) && (
      <SvgUse
        use='arrow-drop'
        className='button-modern__arrow'
      />
    )}

    <span className='button-modern__content'>
      {isPreloading ? (
        <div className='button-modern__preloader'>
          <AnimatedStar />
        </div>
      ) : (
        parse(children ?? '')
      )}
    </span>

    {arrowed === ArrowPositions.RIGHT && (
      <SvgUse
        use='arrow-drop'
        className='button-modern__arrow'
      />
    )}
  </a>
);
