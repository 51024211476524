import React from 'react';
import { useComponentRegistry } from '@bem-react/di';

import { SlideTypes } from '@features/SliderConnector/enums/SlideTypes';
import { TextSlide } from '@features/TextSlide/TextSlide';
import { IComponentRegistry } from '@features/SliderConnector/interfaces/IComponentRegistry';
import { ISlide } from '@features/SliderConnector/interfaces/ISlide';

export const sliderConnectorRegistryToken = 'SliderConnectorComponent';

export interface IProps {
  slides: ISlide[];
  timeout?: number;
  isNotBorderedBottom?: boolean;
}

export const SliderConnector: React.FC<IProps> = ({ slides, timeout, isNotBorderedBottom }) => {
  const { Slider, AbonementSlide, EventSlide } = useComponentRegistry<IComponentRegistry>(sliderConnectorRegistryToken);

  return (
    <Slider
      slides={slides}
      timeout={timeout}
      isNotBorderedBottom={isNotBorderedBottom}
    >
      {slides.map((item, idx) => {
        switch (item.type) {
          case SlideTypes.ABONEMENT:
            return (
              <AbonementSlide
                key={idx}
                {...item.slide}
              />
            );
          case SlideTypes.TEXT:
            return (
              <TextSlide
                key={idx}
                {...item.slide}
              />
            );
          case SlideTypes.EVENT:
            return (
              <EventSlide
                key={idx}
                {...item.slide}
              />
            );
        }
      })}
    </Slider>
  );
};
