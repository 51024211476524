import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';

import { getLang } from '@common/utils/getLang';

export const i18nInitPromise = i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'ru',
    fallbackNS: false,
    lng: getLang(),
    backend: {
      loadPath: '/i18n/{{lng}}/{{ns}}.json',

      addPath: '/i18n/add/{{lng}}/{{ns}}',

      customHeaders: {
        'Cache-Control': 'max-age=<60>',
      },
    },
    interpolation: {
      format: (value, format) => {
        if (value instanceof Date) {
          return moment.parseZone(value).format(format);
        }
        if (moment.isMoment(value)) {
          return value.format(format);
        }

        return value;
      },
    },
  });

export default i18n;
