export enum AppTokens {
  ABONEMENT_APP_TOKEN = 'AbonementAppToken',
  STADIUM_APP_TOKEN = 'StadiumAppToken',
  MAIN_APP_TOKEN = 'MainAppToken',
  EVENTS_APP_TOKEN = 'EventsAppToken',
  TICKET_TRANSFER_APP_TOKEN = 'TicketTransferAppToken',
  TICKET_CHECKER_APP_TOKEN = 'TicketCheckerAppToken',
  VIP_APP_TOKEN = 'VipAppToken',
  SUBSCRIBE_APP_TOKEN = 'SubscribeAppToken',
  RESERVE_APP_TOKEN = 'ReserveAppToken',
  PAGE_BUILDER_APP_TOKEN = 'PageBuilderAppToken',
  CERTIFICATE_APP_TOKEN = 'CertificateAppToken',
  MATCH_PROGRAM_APP_TOKEN = 'MatchProgramAppToken',
  EVENTSV2_APP_TOKEN = 'EventsV2AppToken',
}
