import { ApiResponse } from '@common/api/ApiResponse';
import { ApiServiceBase } from '@common/api/ApiServiceBase';
import { IPageResponse } from '@common/interfaces/pageResponse/pageResponse';
import { Locales } from '@common/enums/Locales';

export class MainPageApiService extends ApiServiceBase {
  constructor(private readonly locale: Locales = Locales.RU) {
    super();
  }

  private getUrl(url: string): string {
    return `${this.locale === Locales.RU ? '' : this.locale}/api/internal/v1/page/index${url}`;
  }

  public getMainPageInfo(): Promise<ApiResponse<IPageResponse>> {
    return this.get<IPageResponse>(this.getUrl(''));
  }
}
