import React, { SVGProps, memo } from 'react';

import { findSvgSizeFromViewBox } from '@common/components/SvgUse/utils/findSvgSizeFromViewBox';

export interface IProps {
  use: string;
  isSvgSizeFromViewBox?: boolean;
  className?: string;
}

export const SvgUse: React.FC<SVGProps<SVGSVGElement> & IProps> = memo(({ use, isSvgSizeFromViewBox, ...props }) => (
  <svg
    {...(isSvgSizeFromViewBox ? findSvgSizeFromViewBox(props.viewBox) : null)}
    {...props}
  >
    <use xlinkHref={`#${use}`} />
  </svg>
));

export default SvgUse;
