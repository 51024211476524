import { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { ArrowDown, ArrowUp, getCode } from 'keyboard-key';
import 'swiped-events';

import { eventSelectorResultScrollHandler } from '@features/DynamicPage/components/MainPage/utils/eventSelectorResultScrollHandler';
import { Template } from '@common/enums/Template';
import { useIsTablet } from '@common/hooks/useIsTablet';
import { useIsSmallHeight } from '@features/DynamicPage/components/MainPage/hooks/useIsSmallHeight';
import { SwipeDirections } from '@features/DynamicPage/components/MainPage/enums/SwipeDirections';

export interface IState {
  activeItem: number;
  animatedInItem: number | null;
  animatedOutItem: number | null;
  animationsStatus: { [key in 'in' | 'out']?: boolean };
  isRevert: boolean;
}

export const useAnimatePage = (itemsLength: number, template: Template) => {
  const [state, setState] = useState<IState>({
    activeItem: 0,
    animatedInItem: 0,
    animatedOutItem: null,
    animationsStatus: {},
    isRevert: false,
  });

  const base = document.querySelector<HTMLDivElement>('.base');
  const html = document.querySelector('html');

  const isSmallHeight = useIsSmallHeight(template === Template.MOBILE);
  const isMobile = !useIsTablet() && template === Template.MOBILE;

  const isSimpleScroll = isSmallHeight || isMobile;

  const [lastSimpleScroll, setLastSimpleScroll] = useState(isSimpleScroll);

  const isDisableScroll = state.animatedInItem !== null || state.animatedOutItem !== null;
  const getIsAvailableDownScroll = () => state.activeItem === itemsLength - 1 && !isDisableScroll;
  const getIsAvailableUpScroll = () => {
    const scrollTop = window.pageYOffset || html?.scrollTop || 0;

    return scrollTop > 0 && !isDisableScroll;
  };

  const [swipeDirection, setSwipeDirection] = useState<SwipeDirections>();

  /**
   * При изменении ориентации делаем скролл к верху страницы
   */
  useEffect(() => {
    setLastSimpleScroll(isSimpleScroll);

    if (isSimpleScroll || !lastSimpleScroll) {
      return;
    }

    setTimeout(() => window.scroll(0, 0), 300);
  }, [isSimpleScroll]);

  /**
   * После загрузки страницы делаем скролл к верху экрана
   */
  useEffect(() => {
    setTimeout(() => window.scroll(0, 0), 1000);
  }, []);

  /**
   * Блокируем скролл на таблетке
   */
  const onTouchMove = (e: TouchEvent) => {
    const { isWheelEventOnEventSelectorResult, isEventSelectorResultScrolledToBottom } =
      eventSelectorResultScrollHandler(e.target);

    const isSwipeUp = swipeDirection === SwipeDirections.UP;

    if (isWheelEventOnEventSelectorResult && isEventSelectorResultScrolledToBottom && isSwipeUp) {
      e.preventDefault();

      return;
    }

    const isAvailableScroll = isSimpleScroll || getIsAvailableDownScroll() || isWheelEventOnEventSelectorResult;

    if (isAvailableScroll) {
      return;
    }

    e.preventDefault();
  };

  /**
   * Переключение на следующую страницу
   */
  const nextPage = () =>
    setState(state => {
      const isLastElement = state.activeItem === itemsLength - 1;

      if (isLastElement || isSimpleScroll || isDisableScroll || getIsAvailableDownScroll()) {
        return state;
      }

      return {
        ...state,
        activeItem: state.activeItem + 1,
        animatedInItem: state.activeItem + 1,
        animatedOutItem: state.activeItem,
        isRevert: false,
      };
    });

  /**
   * Переключение на предыдущую страницу
   */
  const prevPage = () =>
    setState(state => {
      const isFirstElement = state.activeItem === 0;

      if (isFirstElement || isSimpleScroll || isDisableScroll || getIsAvailableUpScroll()) {
        return state;
      }

      return {
        ...state,
        activeItem: state.activeItem - 1,
        animatedInItem: state.activeItem - 1,
        animatedOutItem: state.activeItem,
        isRevert: true,
      };
    });

  /**
   * Метод с дебаунсом, который срабатывает при событии onWheel
   */
  const onScroll = useCallback(
    debounce(
      (deltaY: number) => {
        if (deltaY > 0) {
          nextPage();

          return;
        }

        prevPage();
      },
      200,
      { leading: true, trailing: false },
    ),
    [isDisableScroll],
  );

  /**
   * Обработчик события нажатия на кнопки вверх/вниз
   */
  const onKeyUp = (e: KeyboardEvent) => {
    const code = getCode(e);

    switch (code) {
      case ArrowDown:
        e.preventDefault();
        e.stopPropagation();
        nextPage();

        return;
      case ArrowUp:
        e.preventDefault();
        e.stopPropagation();
        prevPage();

        return;
    }
  };

  /**
   * Обработчик события onWheel
   */
  const onWheel = (e: WheelEvent) => {
    if (isSimpleScroll) {
      return;
    }

    if (e.deltaY >= 0 && getIsAvailableDownScroll()) {
      return;
    }

    if (e.deltaY <= 0 && getIsAvailableUpScroll()) {
      return;
    }

    const { isWheelEventOnEventSelectorResult, isEventSelectorResultScrolledToBottom } =
      eventSelectorResultScrollHandler(e.target);

    if (isWheelEventOnEventSelectorResult && isEventSelectorResultScrolledToBottom && e.deltaY >= 0) {
      e.preventDefault();

      return;
    }

    if (isWheelEventOnEventSelectorResult) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    onScroll(e.deltaY);

    return false;
  };

  /**
   * Обработчики события свайпа
   */
  const onTouchMoveUp = (e: Event) => {
    setSwipeDirection(SwipeDirections.UP);

    const { isWheelEventOnEventSelectorResult } = eventSelectorResultScrollHandler(e.target);

    if (isMobile || isWheelEventOnEventSelectorResult) {
      return;
    }

    nextPage();
  };

  const onTouchMoveDown = (e: Event) => {
    setSwipeDirection(SwipeDirections.DOWN);

    const { isWheelEventOnEventSelectorResult } = eventSelectorResultScrollHandler(e.target);

    if (isMobile || isWheelEventOnEventSelectorResult) {
      return;
    }

    prevPage();
  };

  useEffect(() => {
    if (!base) {
      return;
    }

    base.addEventListener('wheel', onWheel);
    base.addEventListener('swiped-down', onTouchMoveDown);
    base.addEventListener('swiped-up', onTouchMoveUp);
    base.addEventListener('touchmove', onTouchMove);
    document.addEventListener('keydown', onKeyUp);

    return () => {
      base.removeEventListener('wheel', onWheel);
      base.removeEventListener('swiped-down', onTouchMoveDown);
      base.removeEventListener('swiped-up', onTouchMoveUp);
      base.removeEventListener('touchmove', onTouchMove);
      document.removeEventListener('keydown', onKeyUp);
    };
  }, [isDisableScroll, state, isMobile, isSimpleScroll, swipeDirection]);

  return {
    nextPage,
    prevPage,
    setState,
    ...state,
  };
};
