import { useCallback } from 'react';
import { DayModifiers, DateUtils } from 'react-day-picker';

import { DayModifierClassNames } from '@features/EventSelector/components/DateRangePicker/enums/DayModifierClassNames';
import { DateTypes } from '@features/EventSelector/enum/DateTypes';
import { IDateRange } from '@features/EventSelector/components/DateRangePicker/interfaces/dateRange';
import { setOutsideDateRange } from '@features/EventSelector/components/DateRangePicker/utils/setOutsideDateRange';

interface IParams {
  range: IDateRange;
  onDateRangeChange: (range: IDateRange) => void;
  currentDate: DateTypes | null;
  setCurrentDate: (dateType: DateTypes | null) => void;
}

export const useOnDayClick = ({ range, onDateRangeChange, currentDate, setCurrentDate }: IParams) => {
  const { isDayBefore, isDayAfter } = DateUtils;
  const isCurrentDateTo = currentDate === DateTypes.TO;
  const isCurrentDateFrom = currentDate === DateTypes.FROM;

  return useCallback(
    (day: Date, modifiers: DayModifiers) => {
      if (!currentDate || modifiers[DayModifierClassNames.DISABLED]) {
        return;
      }

      const isClickedDayBeforeRange = isCurrentDateTo && range.from && isDayBefore(day, range.from);

      const isClickedDayAfterRange = isCurrentDateFrom && range.to && isDayAfter(day, range.to);

      if (isClickedDayBeforeRange || isClickedDayAfterRange) {
        setOutsideDateRange({ day, range, currentDate, setRange: onDateRangeChange });

        setCurrentDate(null);

        return;
      }

      if ((!range.from && range.to) || (!range.to && range.from)) {
        setCurrentDate(null);
      }

      onDateRangeChange({
        from: currentDate === DateTypes.FROM ? day : range.from,
        to: currentDate === DateTypes.TO ? day : range.to,
      });
    },
    [range, currentDate],
  );
};
