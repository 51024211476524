import React from 'react';
import classNames from 'classnames';

import { EventSizeTypes } from '@features/Event/enum/EventSizeTypes';

interface IProps {
  description: string;
  eventSize: EventSizeTypes;
}

export const TextBlock: React.FC<IProps> = ({ description, eventSize }) => (
  <div
    className={classNames('date-block', {
      [`date-block--${eventSize}`]: eventSize,
    })}
  >
    <div className='date-block__date'>{description}</div>
  </div>
);
