import React from 'react';
import classNames from 'classnames';

const ButtonBase = (props, ref) => {
  const { className, component, disabled, tabIndex, type = 'button', ...nestedProps } = props;

  const buttonProps = {};
  let Component = component || 'button';
  if (Component === 'button' && nestedProps.href) {
    Component = 'a';
  }
  if (Component === 'button') {
    buttonProps.type = type;
    buttonProps.disabled = disabled;
  } else {
    buttonProps['aria-disabled'] = disabled;
  }

  return (
    <Component
      ref={ref}
      className={classNames(className, 'button-base', {
        _disabled: disabled,
      })}
      tabIndex={disabled ? -1 : tabIndex}
      {...buttonProps}
      {...nestedProps}
    />
  );
};

export default React.forwardRef(ButtonBase);
