import React from 'react';
import classNames from 'classnames';

import { SvgUse } from '@common/components/SvgUse/SvgUse';
import { IVariant } from '@features/EventSelector/interfaces/variant';

export interface IProps {
  variants: IVariant[];
  onChange: (value: string) => void;
  value?: string;
  isLight?: boolean;
}

export const ArrowSelect: React.FC<IProps> = ({ variants, onChange, value, isLight }) => (
  <div className='event-selector__arrow-input-wrapper'>
    <select
      onChange={e => onChange(e.target.value)}
      className={classNames('event-selector__arrow-input', {
        'event-selector__arrow-input--light': isLight,
      })}
      value={value}
    >
      <option hidden={true} />
      {variants.map((variant, idx) => (
        <option
          key={idx}
          value={variant.value}
        >
          {variant.label}
        </option>
      ))}
    </select>

    <SvgUse
      use='little-arrow'
      className={classNames('event-selector__arrow-input-icon', {
        'event-selector__arrow-input-icon--light': isLight,
      })}
    />
  </div>
);
