import React, { MouseEvent } from 'react';

import { IButton } from '@common/interfaces/commonResponse/button';
import { getOnButtonClick } from '@features/MainButton/utils/getOnButtonClick';
import { IWithMainButtonState, withMainButtonState } from '@features/MainButton/hocs/withMainButtonState';
import { ButtonModern, IButtonModern } from '@common/components/ButtonModern/ButtonModern';
import { getIsFetching } from '@features/MainButton/utils/getIsFetching';

interface IProps extends IButtonModern {
  button: IButton;
  onClick?: () => void;
  notBlockLinkOnClick?: () => void;
  styledClassName?: string;
  className?: string;
}

/**
 * Многофункциональная кнопка.
 * Для создания кнопки используется компонент ButtonModern
 * компоненту необходимы стили из '../../blocks/common/button-modern/button-modern.less';
 * @param button
 * @param onClick
 * @param notBlockLinkOnClick
 * @param fetchStatus
 * @param showPopup
 * @param onCheckPersonRestrictions
 * @param className
 * @param props
 * @constructor
 */

export const MainButtonComponent: React.FC<IProps & IWithMainButtonState> = ({
  button,
  onClick,
  notBlockLinkOnClick,
  fetchStatus,
  showPopup,
  onCheckPersonRestrictions,
  styledClassName,
  ...props
}) => {
  const onButtonClick = getOnButtonClick({
    action: button.action,
    onCheckPersonRestrictions,
    showPopup,
  });

  const isFetching = getIsFetching({
    action: button.action,
    fetchStatus,
  });

  const onClickHandler = (event: MouseEvent) => {
    if (onClick || onButtonClick) {
      event.preventDefault();

      onButtonClick && onButtonClick();
      onClick && onClick();
    }

    if (notBlockLinkOnClick) {
      notBlockLinkOnClick();
    }
  };

  return (
    <ButtonModern
      href={button.action.href}
      isPreloading={isFetching}
      isActive={button.action.active}
      onClick={onClickHandler}
      target={button.action.isExternalReference ? '_blank' : '_self'}
      styledClassName={styledClassName}
      {...props}
    >
      {button.text}
    </ButtonModern>
  );
};

export const MainButton = withMainButtonState(MainButtonComponent);
