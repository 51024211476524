import React from 'react';

import { AbonementCardHeaderIcon } from '@features/AbonementCard/components/AbonementCardHeaderIcon';
import { AbonementTooltip } from '@common/components/AbonementTooltip/AbonementTooltip';
import { IAdvantage } from '@common/interfaces/commonResponse/advantage';
import { INewLabel } from '@common/interfaces/commonResponse/newLabel';
import { Emoji } from '@common/components/Emoji/Emoji';
import { getUniqueId } from '@common/utils/getUniqueId';

export interface IProps {
  title: string;
  icon: string;
  newLabel: INewLabel;
  advantages?: IAdvantage[];
}

const TOOLTIP_ID = 'abonement-card-icon';

export const AbonementCardHeader: React.FC<IProps> = ({ icon, title, newLabel, advantages }) => {
  const tooltipId = `${TOOLTIP_ID}-${getUniqueId()}`;

  return (
    <div className='abonement-card-header'>
      <div className='abonement-card-header__logo'>
        <Emoji
          icon={icon}
          size={30}
        />
      </div>
      <div className='abonement-card-header__title'>{title}</div>
      {newLabel.display && <div className='abonement-card-header__label'>{newLabel.text}</div>}
      {advantages?.map((advantage, idx) => (
        <AbonementCardHeaderIcon
          key={idx}
          advantage={advantage}
          tooltipId={tooltipId}
        />
      ))}
      <AbonementTooltip
        className='abonement-card-header__tooltip'
        id={tooltipId}
      />
    </div>
  );
};
