import React, { useRef } from 'react';
import useClickAway from 'react-use/lib/useClickAway';
import MomentLocaleUtils from 'react-day-picker/moment';
import classNames from 'classnames';

import { getLang } from '@common/utils/getLang';
import { Locales } from '@common/enums/Locales';
import { DayPickerWrapper } from '@common/components/DayPickerWrapper/DayPickerWrapper';
import { DateTypes } from '@features/EventSelector/enum/DateTypes';
import { ArrowInput } from '@features/EventSelector/components/ArrowInput/ArrowInput';
import { useDateRange } from '@features/EventSelector/components/DateRangePicker/hooks/useDateRange';
import { NavBar } from '@common/components/DatePickerNavBar/NavBar';
import { getModifiers } from '@features/EventSelector/components/DateRangePicker/utils/getModifiers';
import { getFormattedMonthTitle } from '@features/EventSelector/components/DateRangePicker/utils/getFormattedMonthTitle';
import { getFormattedDay } from '@features/EventSelector/components/DateRangePicker/utils/getFormattedDay';
import { IDateRange } from '@features/EventSelector/components/DateRangePicker/interfaces/dateRange';

interface IProps {
  dateFrom: Date;
  onDateRangeChange: (range: IDateRange) => void;
  dateRange: IDateRange;
  isLight?: boolean;
  monthCount?: 1 | 2;
  isDatePickerUp?: boolean;
}

export const DateRangePicker: React.FC<IProps> = ({
  dateFrom,
  onDateRangeChange,
  dateRange,
  isLight,
  monthCount = 2,
  isDatePickerUp,
}) => {
  const locale = getLang();

  const dateRangeRef = useRef<HTMLDivElement | null>(null);

  const { hoveredRange, currentDate, setCurrentDate, onDayClick, onDayMouseEnter, onDayMouseLeave } = useDateRange({
    onDateRangeChange,
    range: dateRange,
  });

  useClickAway(dateRangeRef, () => {
    setCurrentDate(null);
  });

  const modifiers = getModifiers({ range: dateRange, hoveredRange, dateFrom });

  return (
    <div
      ref={dateRangeRef}
      className='date-range-picker'
    >
      <div className='date-range-picker__dates-wrapper'>
        с
        <div className='date-range-picker__date'>
          <ArrowInput
            value={getFormattedDay(dateRange.from)}
            isSelected={currentDate === DateTypes.FROM}
            onClick={() => setCurrentDate(DateTypes.FROM)}
            isLight={isLight}
          />
        </div>
        по
        <div className='date-range-picker__date'>
          <ArrowInput
            value={getFormattedDay(dateRange.to)}
            isSelected={currentDate === DateTypes.TO}
            onClick={() => setCurrentDate(DateTypes.TO)}
            isLight={isLight}
          />
        </div>
      </div>

      {currentDate && (
        <div
          className={classNames('date-range-picker__date-picker', {
            'date-range-picker__date-picker--position-top': isDatePickerUp,
          })}
        >
          <DayPickerWrapper
            numberOfMonths={monthCount}
            navbarElement={props => <NavBar {...props} />}
            className='date-picker--events'
            localeUtils={{
              ...MomentLocaleUtils,
              formatMonthTitle: getFormattedMonthTitle,
            }}
            locale={locale}
            firstDayOfWeek={locale === Locales.RU ? 1 : 0}
            onDayClick={onDayClick}
            modifiers={modifiers}
            onDayMouseEnter={onDayMouseEnter}
            onDayMouseLeave={onDayMouseLeave}
            month={dateRange.from || dateFrom}
            fromMonth={dateFrom}
          />
        </div>
      )}
    </div>
  );
};
