import React from 'react';
import { SwitchTransition } from 'react-transition-group';
import CSSTransition from 'react-transition-group/CSSTransition';

export interface IProps {
  item: string | undefined;
}

export const ScrollBarTransition: React.FC<IProps> = ({ item, children }) => (
  <SwitchTransition mode='out-in'>
    <CSSTransition
      timeout={500}
      key={item || 'out'}
      classNames='scroll-bar__switch-'
    >
      {children || <div />}
    </CSSTransition>
  </SwitchTransition>
);
