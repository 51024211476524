import { css } from 'styled-components';

import { ScreenResolution } from '@common/components/ThemeProvider/enums/ScreenResolution';
import { TextColor } from '@common/components/ThemeProvider/enums/TextColor';
import { BaseColor } from '@common/components/ThemeProvider/enums/BaseColor';

export type IDefaultTheme = typeof defaultTheme;

// TODO Перенести глобальные стили проекта в тему,
//  разобраться с неймингами, определить где факапы дизайнера,
//  много размеров шрифтов, интервалов и цветов
export const defaultTheme = {
  indents: {
    sectionIndent: css`
      padding-bottom: 40px;
      @media (max-width: ${ScreenResolution.TABLET}) {
        padding-bottom: 20px;
      }
    `,
  },
  eventsList: {
    // TODO убрать selectedCard - тема общая для эвент листа
    selectedCard: {
      captions: {
        dateTime: css`
          font-family: FuturaDemi, Arial, sans-serif;
          text-transform: uppercase;
          font-size: 20px;
          font-weight: 600;
          line-height: 30px;
          letter-spacing: 0.1em;
          color: ${TextColor.ACCENT};

          @media (max-width: ${ScreenResolution.TABLET}) {
            font-size: 16px;
          }

          @media (max-width: ${ScreenResolution.MOBILE}) {
            font-size: 14px;
            line-height: 20px;
          }
        `,
        teamName: css`
          font-family: FuturaDemi, Arial, sans-serif;
          text-transform: uppercase;
          font-size: 21px;
          font-weight: 600;
          line-height: 32px;
          letter-spacing: 0.1em;
          color: ${TextColor.ACCENT};

          @media (max-width: ${ScreenResolution.TABLET}) {
            font-size: 16px;
            line-height: 24px;
          }

          @media (max-width: ${ScreenResolution.MOBILE}) {
            font-size: 14px;
            line-height: 20px;
          }
        `,
        tooltipText: css`
          font-family:
            PT Sans,
            sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0.05em;
        `,
        adsLabel: css`
          font-family:
            PT Sans,
            sans-serif;
          font-size: 10px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.1em;
          color: ${TextColor.ACCENT};
          text-transform: uppercase;

          @media (max-width: ${ScreenResolution.MOBILE}) {
            line-height: 14px;
          }
        `,
        adsText: css`
          font-family:
            PT Sans,
            sans-serif;
          font-size: 16px;
          font-weight: 700;
          line-height: 30px;
          letter-spacing: 0.05em;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: ${TextColor.NEUTRAL_100};
        `,
        leagueName: css`
          font-family:
            PT Sans,
            sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 30px;
          letter-spacing: 0.05em;
          color: ${TextColor.ACCENT};

          @media (max-width: ${ScreenResolution.TABLET}) {
            font-size: 12px;
            line-height: 15px;
          }

          @media (max-width: ${ScreenResolution.MOBILE}) {
            font-size: 14px;
            line-height: 20px;
          }
        `,
        textOrLink: css`
          font-family: FuturaDemi, Arial, sans-serif;
          text-transform: uppercase;
          font-size: 14px;
          font-weight: 600;
          line-height: 16px;
          letter-spacing: 0.1em;

          @media (max-width: ${ScreenResolution.TABLET}) {
            font-size: 10px;
            font-weight: 600;
          }

          @media (max-width: ${ScreenResolution.MOBILE}) {
            font-size: 12px;
          }
        `,

        tagsText: css`
          font-family: FuturaDemi, Arial, sans-serif;

          font-size: 14px;
          font-weight: 600;
          line-height: 18px;
          letter-spacing: 0.1em;
          color: ${TextColor.ACCENT};
          text-transform: uppercase;

          @media (max-width: ${ScreenResolution.TABLET}) {
            font-size: 10px;
          }

          @media (max-width: ${ScreenResolution.MOBILE}) {
            font-size: 10px;
            line-height: 16px;
          }
        `,
        button: css`
          font-family: FuturaDemi, Arial, sans-serif;
          text-transform: uppercase;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 0.1em;

          @media (max-width: ${ScreenResolution.TABLET}) {
            font-size: 12px;
          }
        `,
        imageCaption: css`
          font-family: FuturaDemi, Arial, sans-serif;
          font-size: 16px;
          font-weight: 500;
          line-height: 30px;
          letter-spacing: 0.07em;
          text-align: left;
          @media (max-width: ${ScreenResolution.TABLET}) {
            font-size: 10px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.1em;
          }
        `,
      },
    },
  },
  matchProgram: {
    font: {
      header: {
        h1: css`
          font-family: FuturaPT-Bold, Arial, sans-serif;
          font-size: 48px;
          font-weight: 500;
          line-height: 55px;
          letter-spacing: 0.48px;

          @media (max-width: ${ScreenResolution.TABLET}) {
            font-size: 24px;
            line-height: normal;
            letter-spacing: 1.2px;
            color: ${TextColor.NEUTRAL_300};
          }
        `,
        // TODO Проверить при рефакторинге не перекрывается ли h# другими стилями в проекте,

        // заголовки блоков
        h2: css`
          font-family: FuturaDemi, Arial, sans-serif;
          font-size: 36px;
          font-weight: 500;
          line-height: 40px;
          letter-spacing: 0.36px;

          @media (max-width: ${ScreenResolution.TABLET}) {
            font-size: 20px;
            line-height: 30px;
            letter-spacing: 1px;
            color: ${TextColor.NEUTRAL_300};
          }
        `,
        // Подзаголовки блоков
        h3: css`
          font-family: FuturaDemi, Arial, sans-serif;
          font-size: 30px;
          font-weight: 500;
          line-height: 36px;
          letter-spacing: 0.3px;
          @media (max-width: ${ScreenResolution.TABLET}) {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.16px;
            color: ${TextColor.NEUTRAL_300};
          }
        `,
        h4: css`
          font-family: FuturaDemi, Arial, sans-serif;
          font-size: 24px;
          font-weight: 500;
          line-height: 30px;
          letter-spacing: 1.2px;

          @media (max-width: ${ScreenResolution.TABLET}) {
            font-size: 18px;
            letter-spacing: 1px;
            color: ${TextColor.NEUTRAL_300};
          }
        `,
        h5: css`
          font-family: FuturaDemi, Arial, sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px; /* 140% */
          letter-spacing: 1px;
          @media (max-width: ${ScreenResolution.TABLET}) {
            font-size: 16px;
            letter-spacing: 0.8px;
          }
        `,
        // Штамп даты в MatchProgram
        h6: css`
          font-family: FuturaDemi, Arial, sans-serif;
          font-size: 16px;
          font-weight: 500;
          line-height: 30px;
          letter-spacing: 1.6px;
          text-transform: uppercase;

          @media (max-width: ${ScreenResolution.TABLET}) {
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 1.4px;
            color: ${TextColor.NEUTRAL_300};
          }
        `,
      },
      body: {
        base: css`
          font-family:
            PT Sans,
            sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.8px;

          @media (max-width: ${ScreenResolution.TABLET}) {
            line-height: 30px;
            color: ${TextColor.NEUTRAL_300};
          }
        `,
      },
      caption: {
        // Стили в навигации
        navigation: css`
          font-family: FuturaDemi, Arial, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 1.6px;
          text-transform: uppercase;
        `,
        // Стили в кнопках
        controls: css`
          font-family: FuturaDemi, Arial, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 1.4px;
          text-transform: uppercase;
        `,
        // Лейблы фильтров кейтеринга
        filterLabel: css`
          font-family: FuturaDemi, Arial, sans-serif;
          font-size: 20px;
          font-weight: 500;
          line-height: 30px;
          letter-spacing: 2px;
          text-transform: uppercase;
        `,
        // Заголовок у названий места в карточке программы
        locationLabel: css`
          font-family: FuturaDemi, Arial, sans-serif;
          font-size: 20px;
          font-weight: 500;
          line-height: 30px;
          letter-spacing: 2px;
          text-transform: uppercase;
          @media (max-width: ${ScreenResolution.TABLET}) {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 1.6px;
          }
        `,

        // Номер яруса в меню этажей стадиона
        stageLabel: css`
          font-family: FuturaDemi, Arial, sans-serif;
          font-size: 12px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0.6px;
          text-transform: uppercase;

          @media (max-width: ${ScreenResolution.TABLET}) {
            font-size: 7.5px;
            line-height: 13px;
            letter-spacing: 0.4px;
          }
        `,
        // короткое описание в попапе сервисов
        preview: css`
          font-family: FuturaDemi, Arial, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
          letter-spacing: 0.7px;
          text-transform: uppercase;
        `,
        // Номер этажа в меню этажей стадиона
        floorLabel: css`
          font-family: FuturaDemi, Arial, sans-serif;
          font-size: 36px;
          font-weight: 500;
          line-height: 40px;
          letter-spacing: 0.36px;
          @media (max-width: ${ScreenResolution.TABLET}) {
            font-size: 23px;
            line-height: 25.5px;
            letter-spacing: 0.23px;
          }
        `,
        floorInfoLabel: {
          medium: css`
            font-family: FuturaDemi, Arial, sans-serif;
            font-size: 30px;
            font-weight: 500;
            letter-spacing: 1.5px;
          `,
          large: css`
            font-family: FuturaDemi, Arial, sans-serif;
            font-size: 60px;
            font-weight: 500;
            letter-spacing: 3px;
          `,
        },
      },
    },
    // TODO вынести из matchProgram наверх по иерархии объекта темы - здесь удалить и отрефакторить на общий indents
    indents: {
      sectionIndent: css`
        padding-bottom: 40px;
        @media (max-width: ${ScreenResolution.TABLET}) {
          padding-bottom: 20px;
        }
      `,
    },
    // TODO вынести цвета наверх по иерархии объекта темы
    colors: {
      text: TextColor,
      base: BaseColor,
    },
  },
};
