import React from 'react';
import classNames from 'classnames';

import { EventSizeTypes } from '@features/Event/enum/EventSizeTypes';

export interface IProps {
  logo?: string;
  name?: string;
  isHighlighted?: boolean;
  eventSize: EventSizeTypes;
  transparent?: boolean;
}

export const Member: React.FC<IProps> = ({ logo, name, isHighlighted, eventSize, transparent }) => (
  <div
    className={classNames('member', {
      'member--highlighted': isHighlighted,
      [`member--${eventSize}`]: eventSize,
      'member--transparent': transparent,
    })}
  >
    <img
      className='member__logo'
      src={logo}
      alt={name}
    />
  </div>
);
